import 'firebase/database';

import firebase from 'firebase/app';

export const db = firebase
    .initializeApp({
        apiKey: "AIzaSyASFlP7QLEy461rjAQwSxB5BKDphZpN22s",
        authDomain: "lunchrun-v2.firebaseapp.com",
        databaseURL: "https://lunchrun-v2-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "lunchrun-v2",
        storageBucket: "lunchrun-v2.appspot.com",
        messagingSenderId: "15821913644",
        appId: "1:15821913644:web:90ea6dabd7c120799d9b89",
        measurementId: "G-0PH7GL83ZZ"
    })
    .database();
